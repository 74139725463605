import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const TermsOfServicePage = () => (
  <Layout>
    <SEO title="Terms Of Service" />
    <div className="container py-5">
      <h1 className="font-weight-bold mb-4">TERMS OF SERVICE</h1>
      <p>
        Welcome to Energi Impact. Please read these Terms of Service carefully.
        By accessing or using the Site in any way, you agree to be bound by the
        Terms described herein and incorporated by reference. If you do not
        agree to these Terms, do not use the Site.
      </p>
      <p>
        These Terms of Use (“Terms”) apply to your access to, and use of, the
        websites of Energi Impact Core Limited and its subsidiaries and
        affiliated companies (“Energi Impact,” “we” or “us”), including
        www.Energi Impact.world (collectively, the “Site”). These Terms do not
        alter the terms or conditions of any other agreement you may have with
        Energi Impact for products, services or otherwise (collectively,
        “Agreement”). To the extent there is a conflict between any Agreement
        and these Terms, the terms of the Agreement shall govern.
      </p>
      <p>
        Energi Impact reserves the right to change or modify these Terms at any
        time in its sole discretion and without prior specific notice to you.
        Energi Impact will change the “Last Revised” date at the top of these
        Terms when such changes or modifications are made, which shall be
        effective immediately. Your continued use of the Website will indicate
        your acceptance of such changed or modified Terms.
      </p>
      <p>
        Energi Impact reserves the right, in its sole discretion, to modify,
        suspend, or cancel the Website or any portion of the Website or Services
        without prior notice to you, and to block or prevent your future access
        to, and use of, the Site.
      </p>

      <h2 className="h4 font-weight-bold my-4">1. ELIGIBILITY</h2>
      <p>
        By accessing or using the Site, you represent and warrant that you have
        not previously been suspended or removed from the Site. You represent
        and warrant that you are not: (a) located in, under the control of, or a
        national or resident of any country to which the United States has
        embargoed goods or services; (b) identified as a “Specially Designated
        National”, or (c) placed on the Commerce Department’s Denied Persons
        List. You further represent and warrant that you will not use the Site
        if the laws of your country prohibit you from doing so in accordance
        with these Terms.
      </p>
      <p>
        You further represent that you are a legal adult or have permission from
        a parent or guardian to access the Site.
      </p>

      <h2 className="h4 font-weight-bold my-4">2. PRIVACY POLICY</h2>
      <p>
        Please refer to our Privacy Policy for information about how Energi
        Impact collects, uses and discloses information about its users.
      </p>

      <h2 className="h4 font-weight-bold my-4">
        3. COPYRIGHT AND LIMITED LICENSE
      </h2>
      <p>
        Unless otherwise indicated on the Site, the Site and all content and
        other materials on the Site, including, without limitation, the Energi
        Impact logo and all designs, text, graphics, pictures, information,
        data, software, sound files, other files and the selection and
        arrangement thereof (collectively, “Energi Impact Materials”) are the
        proprietary property of Energi Impact or its licensors or users and are
        protected by U.S. and international copyright laws.
      </p>
      <p>
        You are granted a limited, non-sublicensable license to access and use
        the Site and Energi Impact Materials subject to these Terms. Except as
        expressly permitted on the Site, in these Terms or otherwise in writing
        by Energi Impact, such license does not include: (a) any resale or
        commercial use of the Site or the Energi Impact Materials; (b) the
        distribution, public performance or public display of any Energi Impact
        Materials; (c) modifying or otherwise making any derivative uses of the
        Site and the Energi Impact Materials, or any portion thereof; (d) use of
        any data mining, robots or similar data gathering or extraction methods;
        (e) downloading (other than the page caching) of any portion of the
        Site, the Energi Impact Materials or any information contained therein;
        or (f) any use of the Site or the Energi Impact Materials other than for
        its intended purpose. Any use of the Site or the Energi Impact Materials
        other than as specifically authorized herein, without the prior written
        permission of Energi Impact, is strictly prohibited and will terminate
        the license granted herein. Such unauthorized use may also violate
        applicable laws, including, without limitation, copyright and trademark
        laws and applicable communications regulations and statutes. Unless
        explicitly stated herein, nothing in these Terms will be construed as
        conferring any license to intellectual property rights, whether by
        estoppel, implication or otherwise. This license is revocable at any
        time.
      </p>

      <h2 className="h4 font-weight-bold my-4">4. COPYRIGHT COMPLAINTS</h2>
      <p>
        If you believe anything on the Site infringes upon any copyright which
        you own or control, you may file a notification of such infringement.
      </p>
      <p>
        Please see 17 U.S.C. §512(c)(3) for the requirements of a proper
        notification. You should note that if you knowingly misrepresent in your
        notification that the material or activity is infringing, you will be
        liable for any damages, including costs and attorneys’ fees, incurred by
        us or the alleged infringer as the result of our relying upon such
        misrepresentation in removing or disabling access to the material or
        activity claimed to be infringing.
      </p>

      <h2 className="h4 font-weight-bold my-4">5. USER CONTENT</h2>
      <p>
        The Site may include interactive areas or services (“Interactive Areas”)
        in which you or other users may create, post, send or store ads,
        messages, materials, data, information, text, music, sound, photos,
        video, graphics, applications, code, links or other items or content on
        the Site (“User Content”). By using the Site, you agree not to post,
        upload to, transmit, distribute, store, create or otherwise publish or
        send through the Site any of the following:
      </p>
      <p>
        a. User Content that is unlawful, libelous, defamatory, obscene,
        pornographic, indecent, lewd, suggestive, harassing, threatening,
        abusive, inflammatory, fraudulent, false, misleading or otherwise
        objectionable; b. User Content that would constitute, encourage or
        provide instructions for a criminal offense, violate the rights of any
        party or that would otherwise create liability or violate any local,
        state, national or international law; c. User Content that may impinge
        upon the publicity, privacy or data protection rights of others,
        including pictures or information about another individual where you
        have not obtained such individual’s consent; d. User Content that may
        infringe any patent, trademark, trade secret, copyright or other
        intellectual or proprietary right of any party. By posting any User
        Content, you represent and warrant that you have the lawful right to
        distribute and reproduce such User Content; e. User Content that
        impersonates any person or entity or otherwise misrepresents your
        affiliation with a person or entity; f. Viruses, corrupted data or other
        harmful, disruptive or destructive files; or g. User Content that, in
        the sole judgment of Energi Impact, is objectionable or which restricts
        or inhibits any other person from using or enjoying the Interactive
        Areas, the Site, or which may expose Energi Impact, to any harm or
        liability of any type.
      </p>
      <p>
        Energi Impact does not control, take responsibility for or assume
        liability for any User Content posted, stored or uploaded by you or any
        third party, or for any loss or damage thereto, nor is Energi Impact
        liable for any user conduct or any mistakes, defamation, slander, libel,
        omissions, falsehoods, obscenity, pornography or profanity you may
        encounter. When you participate in Interactive Areas, you understand
        that certain information and content you choose to post may be displayed
        publicly or to select users. You are solely responsible for your use of
        the Site and the Interactive Areas and use them at your own risk.
      </p>
      <p>
        If you become aware of User Content that you believe violates these
        Terms, you may report it by emailing support@Energi Impact.team.
        Enforcement of the Terms, however, is solely in our discretion and the
        absence of enforcement of these Terms in some instances does not
        constitute a waiver of our right to enforce the Terms in other
        instances. In addition, these Terms do not create any private right of
        action on the part of any third party or any reasonable expectation or
        promise that the Site will not contain any content that is prohibited by
        the Terms. Although Energi Impact has no obligation to screen, edit or
        monitor any of the User Content posted on the Site, Energi Impact
        reserves the right, and has absolute discretion, to remove, screen or
        edit any User Content posted or stored on the Site at any time and for
        any reason without notice, and you are solely responsible for creating
        backup copies and replacing any User Content you post or store on the
        Site at your sole cost and expense. Any use of the Site in violation of
        these Terms may result in, among other things, termination or suspension
        of your rights to use the Interactive Areas and/or the Site.
      </p>
      <p>
        Except as otherwise provided herein, on the Site or in a separate
        agreement (such as the rules of a Energi Impact contest), Energi Impact
        claims no ownership or control over any User Content. However, if you
        post User Content to the Site, you grant Energi Impact a nonexclusive,
        royalty-free, perpetual, irrevocable and fully sublicensable right to
        use, reproduce, modify, adapt, publish, translate, create derivative
        works from, distribute, perform and display such User Content on our
        websites and on third-party sites. This license will terminate when you
        remove your User Content, except that the license will continue with
        respect to any off-site uses that have already been made and any cached
        or archived uses that may still exist when User Content is removed. By
        posting User Content, you hereby release Energi Impact and its agents
        and employees from any claims that such use, as authorized above,
        violates any of your rights and you understand that you will not be
        entitled to any additional compensation for any use of your User
        Content.
      </p>
      <p>
        By posting User Content to the Site, you represent and warrant that: (a)
        such User Content is non-confidential; (b) you own and control all of
        the rights to the User Content or you otherwise have the right to post
        and use such User Content and to grant the rights to Energi Impact that
        you grant in these Terms; (c) the User Content is accurate and not
        misleading or harmful in any manner; and (d) the User Content, and your
        use and posting thereof in connection with this Site, does not, and will
        not, violate these Terms or any applicable law, rule or regulation.
      </p>

      <h2 className="h4 font-weight-bold my-4">6. REPEAT INFRINGER POLICY</h2>
      <p>
        In accordance with the Digital Millennium Copyright Act (“DMCA”) and
        other applicable law, Energi Impact has adopted a policy of terminating,
        in appropriate circumstances and in Energi Impact’s sole discretion,
        users who are deemed to be repeat infringers. Energi Impact may also, in
        its sole discretion, limit access to the Site and/or terminate the
        Account of any user who infringes any intellectual property rights of
        others, whether or not there is any repeat infringement.
      </p>

      <h2 className="h4 font-weight-bold my-4">7. TRADEMARKS</h2>
      <p>
        “Energi Impact,” “Energi Impact Core,” the Energi Impact logos and any
        other Energi Impact product or service name, logo or slogans contained
        in the Site are trademarks or service marks of Energi Impact (the
        “Energi Impact Marks”) and may not be copied, imitated or used, in whole
        or in part, except as expressly permitted in these Terms or on the Site
        or with the prior written permission of Energi Impact. You may not use
        any metatags or any other “hidden text” utilizing any Energi Impact
        Marks without our prior written permission. In addition, the look and
        feel of the Site, including all page headers, custom graphics, button
        icons and scripts, is the service mark, trademark and/or trade dress of
        Energi Impact and is part of the Energi Impact Marks and may not be
        copied, imitated or used, in whole or in part, without our prior written
        permission except as expressly permitted herein or on the Site. All
        other trademarks, registered trademarks, product names and company names
        or logos mentioned in the Site are the property of their respective
        owners and may not be copied, imitated or used, in whole or in part,
        without the written permission of the applicable trademark holder.
        Reference to any products, services, processes or other information, by
        trade name, trademark, manufacturer, supplier or otherwise does not
        constitute or imply endorsement, sponsorship or recommendation thereof
        by us.
      </p>

      <h2 className="h4 font-weight-bold my-4">8. HYPERLINKS</h2>
      <p>
        You are granted a limited, non-exclusive right to create a text
        hyperlink to the Site, provided such link does not portray Energi Impact
        or any of our Site in a false, misleading, derogatory or otherwise
        defamatory manner and provided further that the linking site does not
        contain any adult or illegal material or any material that is offensive,
        harassing or otherwise objectionable. This limited right may be revoked
        at any time. You may not use any Energi Impact Marks or other
        proprietary graphic of Energi Impact to link to the Site without the
        express written permission of Energi Impact. Further, you may not use,
        frame or utilize framing techniques to enclose any Energi Impact
        trademark, logo or other proprietary information, including the images
        found on the Site, the content of any text or the layout/design of any
        page or form contained on a page of the Site without Energi Impact’s
        express written consent. Except as noted above, you are not conveyed any
        right or license by implication, estoppel or otherwise in or under any
        patent, trademark, copyright or other proprietary right of Energi Impact
        or any third party.
      </p>

      <h2 className="h4 font-weight-bold my-4">9. THIRD PARTY CONTENT</h2>
      <p>
        Energi Impact and its users may provide third party content on the Site
        and may provide links to web pages and content that are not owned or
        controlled by Energi Impact, including but not limited any third party
        advertisements or promotions (collectively the “Third Party Content”) as
        a service to those interested in this information. Energi Impact does
        not control, endorse or adopt any Third Party Content and makes no
        representation or warranties of any kind regarding the Third Party
        Content, including without limitation regarding its accuracy or
        completeness. You acknowledge and agree that Energi Impact is not
        responsible or liable in any manner for any Third Party Content and
        undertakes no responsibility to update or review any Third Party
        Content. Users use such Third Party Content contained therein at their
        own risk. Your business dealings or correspondence with, or
        participation in promotions of, any third parties, and any terms,
        conditions, warranties or representations associated with such dealings
        or promotions, are solely between you and such third parties. Energi
        Impact is not responsible or liable for any loss or damage of any sort
        incurred as the result of any such dealings or promotions or as the
        result of the presence of such Third Party Content on the Site.
      </p>

      <h2 className="h4 font-weight-bold my-4">10. FEEDBACK</h2>
      <p>
        You acknowledge and agree that any materials, including but not limited
        to questions, comments, feedback, suggestions, ideas, plans, notes,
        drawings, original or creative materials or other information, regarding
        Energi Impact or the Site (collectively, “Feedback”) that are provided
        by you, whether by email, posting to the Site or otherwise, are
        non-confidential and will become the sole property of Energi Impact.
        Energi Impact will own exclusive rights, including all intellectual
        property rights, and will be entitled to the unrestricted use and
        dissemination of such Feedback for any purpose, commercial or otherwise,
        without acknowledgment or compensation to you.
      </p>

      <h2 className="h4 font-weight-bold my-4">11. INDEMNIFICATION</h2>
      <p>
        You agree to defend, indemnify and hold harmless Energi Impact, its
        independent contractors, service providers and consultants, and their
        respective directors, employees and agents, from and against any claims,
        damages, costs, liabilities and expenses (including, but not limited to,
        reasonable attorneys’ fees) arising out of or related to: (a) your use
        of the Site; (b) any User Content or Feedback you provide; (c) your
        violation of these Terms; (d) your violation of any rights of another;
        or (e) your conduct in connection with the Site. Some jurisdictions
        limit consumer indemnities, so some or all of the indemnity provisions
        above may not apply to you. If you are obligated to indemnify us, we
        will have the right, in our sole and unfettered discretion, to control
        any action or proceeding and determine whether we wish to settle it, and
        if so, on what terms.
      </p>

      <h2 className="h4 font-weight-bold my-4">12. DISCLAIMER OF WARRANTIES</h2>
      <p>
        ENERGI IMPACT PROVIDES NO GUARANTEE AS TO THE PERFORMANCE OR THE
        UNINTERRUPTED AVAILABILITY OF THE SITE OR THE ENERGI IMPACT MATERIALS.
        THE SITE AND ENERGI IMPACT MATERIALS ARE PROVIDED ON AN “AS IS,” “AS
        AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED. ENERGI IMPACT DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE
        SITE AND THE INFORMATION, CONTENT AND MATERIALS CONTAINED THEREIN.
        ENERGI IMPACT DOES NOT REPRESENT OR WARRANT THAT ENERGI IMPACT MATERIALS
        OR THE SITE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.
        WHILE ENERGI IMPACT ATTEMPTS TO MAKE YOUR ACCESS AND USE OF THE SITE
        SAFE, ENERGI IMPACT CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE
        SITE OR ITS SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;
        THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND
        DISINFECT VIRUSES FROM ANY DOWNLOAD.
      </p>
      <p>
        ENERGI IMPACT RESERVES THE RIGHT TO CHANGE ANY AND ALL CONTENT CONTAINED
        IN THE SITE AT ANY TIME WITHOUT NOTICE. REFERENCE TO ANY PRODUCTS,
        SERVICES, PROCESSES OR OTHER INFORMATION, BY TRADE NAME, TRADEMARK,
        MANUFACTURER, SUPPLIER OR OTHERWISE DOES NOT CONSTITUTE OR IMPLY
        ENDORSEMENT, SPONSORSHIP OR RECOMMENDATION THEREOF, OR ANY AFFILIATION
        THEREWITH, BY ENERGI IMPACT.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED TERMS IN
        CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE DISCLAIMERS IN THIS
        SECTION MAY NOT APPLY TO YOU.
      </p>

      <h2 className="h4 font-weight-bold my-4">13. LIMITATION OF LIABILITY</h2>
      <p>
        IN NO EVENT WILL ENERGI IMPACT, ITS DIRECTORS, EMPLOYEES OR AGENTS BE
        LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR
        ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE,
        LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT
        (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF
        OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE THE SITE,
        THE ENERGI IMPACT MATERIALS OR THE CONTENT OR THE MATERIALS CONTAINED IN
        OR ACCESSED THROUGH THE SITE, INCLUDING WITHOUT LIMITATION ANY DAMAGES
        CAUSED BY OR RESULTING FROM RELIANCE BY USER ON ANY INFORMATION OBTAINED
        FROM ENERGI IMPACT, OR THAT RESULT FROM MISTAKES, OMISSIONS,
        INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES,
        DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE,
        WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE,
        THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO ENERGI IMPACT’S RECORDS,
        PROGRAMS OR SITE. IN NO EVENT WILL THE AGGREGATE LIABILITY OF ENERGI
        IMPACT WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE,
        WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY
        OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY
        TO USE THE SITE EXCEED ANY COMPENSATION YOU PAY, IF ANY, TO ENERGI
        IMPACT FOR ACCESS TO OR USE OF THE SITE. SOME JURISDICTIONS DO NOT ALLOW
        THE LIMITATION OF LIABILITY IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL
        OF THESE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
      </p>

      <h2 className="h4 font-weight-bold my-4">
        14. MODIFICATIONS TO THE SITE
      </h2>
      <p>
        Energi Impact reserves the right to modify or discontinue, temporarily
        or permanently, the Site or any features or portions thereof without
        prior notice. You agree that Energi Impact will not be liable for any
        modification, suspension or discontinuance of the Site or any part
        thereof.
      </p>

      <h2 className="h4 font-weight-bold my-4">15. APPLICABLE LAW AND VENUE</h2>
      <p>
        These Terms and your use of the Site will be governed by and construed
        in accordance with the laws of British Virgin Island, without resort to
        its conflict of law provisions. You agree that any action at law or in
        equity arising out of or relating to these Terms not subject to
        arbitration (as set forth below), will be filed only in the courts
        located in British Virgin Islands and you hereby irrevocably and
        unconditionally consent and submit to the exclusive jurisdiction of such
        courts over any suit, action or proceeding arising out of these Terms.
      </p>

      <h2 className="h4 font-weight-bold my-4">16. ARBITRATION</h2>
      <p>
        PLEASE READ THE FOLLOWING PARAGRAPH CAREFULLY BECAUSE IT REQUIRES YOU TO
        ARBITRATE DISPUTES WITH ENERGI IMPACT AND IT LIMITS THE MANNER IN WHICH
        YOU CAN SEEK RELIEF.
      </p>
      <p>
        You and Energi Impact agree to arbitrate any dispute arising from these
        Terms or relating to the Site, except that you and Energi Impact are not
        required to arbitrate any dispute in which either party seeks equitable
        or other relief for the alleged unlawful use of copyrights, trademarks,
        trade names, logos, trade secrets or patents. ARBITRATION PREVENTS YOU
        FROM SUING IN COURT OR FROM HAVING A JURY TRIAL. You and Energi Impact
        agree that you will notify each other of any dispute within thirty (30)
        days of when it arises, that you will attempt informal resolution prior
        to any demand for arbitration, that any arbitration will occur in
        British Virgin Islands and that arbitration will be conducted
        confidentially by a single arbitrator in accordance with the rules of
        JAMS. You and Energi Impact also agree that the courts in British Virgin
        Islands have exclusive jurisdiction over any appeals of an arbitration
        award and over any suit between the parties not subject to arbitration.
        In any arbitration, the parties will not seek discovery from each other,
        and the arbitrator shall not allow parties to engage in discovery;
        rather, each party shall disclose the evidence supporting their
        positions at some mutually agreeable time and date prior to the final
        hearing. Other than class procedures and remedies discussed below, the
        arbitrator has the authority to grant any remedy that would otherwise be
        available in court. Neither you nor we will participate in a class
        action or class-wide arbitration for any claims covered by these Terms.
        You also agree not to participate in claims brought in an private
        attorney general or representative capacity, or consolidated claims
        involving another person, if Energi Impact is a party to the proceeding.
        If the prohibition against class actions and other claims brought on
        behalf of third parties contained above is found to be unenforceable,
        then that language shall be deemed to have been dropped from the Terms
        and the remaining obligations relating to arbitration shall continue in
        full force and effect.
      </p>

      <h2 className="h4 font-weight-bold my-4">17. SURVIVAL</h2>
      <p>
        Sections 5 (User Content), 7 (Trademarks), 9 (Third Party Content) ,10
        (Feedback), 11 (Indemnification), 12 (Disclaimer of Warranties), 13
        (Limitation on Liability), 15 (Applicable Law and Venue), 16
        (Arbitration), this Section 17 (Survival) and Section 18 (Severability)
        will survive any termination or expiration of these Terms.
      </p>

      <h2 className="h4 font-weight-bold my-4">18. SEVERABILITY</h2>
      <p>
        If any provision of these Terms is deemed unlawful, void or for any
        reason unenforceable, then that provision shall be deemed severable from
        these Terms and will not affect the validity and enforceability of any
        remaining provisions.
      </p>
    </div>
  </Layout>
)
export default TermsOfServicePage
